@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('../../public/static/font/Roboto-Bold.eot');
  src:
    local('Roboto Bold'),
    local('Roboto-Bold'),
    url('../../public/static/font/Roboto-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Roboto-Bold.woff') format('woff'),
    url('../../public/static/font/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('../../public/static/font/Roboto-Regular.eot');
  src:
    local('Roboto'),
    local('Roboto-Regular'),
    url('../../public/static/font/Roboto-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Roboto-Regular.woff') format('woff'),
    url('../../public/static/font/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('../../public/static/font/Roboto-Medium.eot');
  src:
    local('Roboto Medium'),
    local('Roboto-Medium'),
    url('../../public/static/font/Roboto-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Roboto-Medium.woff') format('woff'),
    url('../../public/static/font/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Roboto';
  src: url('../../public/static/font/Roboto-Light.eot');
  src:
    local('Roboto Light'),
    local('Roboto-Light'),
    url('../../public/static/font/Roboto-Light.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Roboto-Light.woff') format('woff'),
    url('../../public/static/font/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../public/static/font/Montserrat-Bold.eot');
  src:
    local('../../public/static/font/Montserrat Bold'),
    local('Montserrat-Bold'),
    url('../../public/static/font/Montserrat-Bold.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Montserrat-Bold.woff') format('woff'),
    url('../../public/static/font/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../public/static/font/Montserrat-Regular.eot');
  src:
    local('../../public/static/font/Montserrat Regular'),
    local('Montserrat-Regular'),
    url('../../public/static/font/Montserrat-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Montserrat-Regular.woff') format('woff'),
    url('../../public/static/font/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../public/static/font/Montserrat-Medium.eot');
  src:
    local('../../public/static/font/Montserrat Medium'),
    local('Montserrat-Medium'),
    url('../../public/static/font/Montserrat-Medium.eot?#iefix')
      format('embedded-opentype'),
    url('../../public/static/font/Montserrat-Medium.woff') format('woff'),
    url('../../public/static/font/Montserrat-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
:focus {
  outline: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type='search'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}
audio,
canvas,
video {
  display: inline-block;
  max-width: 100%;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden] {
  display: none;
}
html {
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
a:focus {
  outline: thin dotted;
}
a:active,
a:hover {
  outline: 0;
  cursor: pointer;
}
img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}
figure {
  margin: 0;
}
form {
  margin: 0;
}
fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  outline: 0;
}
button,
input {
  line-height: normal;
  padding: 0;
  border: 0;
  background: 0;
  box-shadow: 0 0 0 transparent;
}
button,
select {
  text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
  -webkit-appearance: button;
  cursor: pointer;
}
button[disabled],
html input[disabled] {
  cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='search'] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
textarea {
  overflow: auto;
  vertical-align: top;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
button,
html,
input,
select,
textarea {
  color: #222;
}
::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}
::selection {
  background: #b3d4fc;
  text-shadow: none;
}
img {
  vertical-align: middle;
}
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}
textarea {
  resize: vertical;
}
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}
/*a {*/
/*  text-decoration: none;*/
/*  color: inherit;*/
/*}*/

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
::-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
:-ms-input-placeholder {
  color: inherit;
  opacity: 0.5;
}
:-moz-placeholder {
  color: inherit;
  opacity: 0.5;
}
a:active,
a:focus {
  outline: 0;
}
input,
textarea {
  outline: 0;
}
input:active,
textarea:active {
  outline: 0;
}
:focus {
  outline: 0;
}
textarea {
  resize: none;
}
textarea {
  resize: vertical;
}
textarea {
  resize: horizontal;
}
button:active,
button:focus {
  outline: 0;
}
button::-moz-focus-inner {
  border: 0;
}
button:active,
button:focus {
  outline: 0;
}
button::-moz-focus-inner {
  border: 0;
}

button.active.focus,
button.active:focus,
button.focus,
button:active.focus,
button:active:focus,
button:focus {
  outline: 0;
  outline-color: transparent;
  outline-width: 0;
  outline-style: none;
  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
}

input[placeholder] {
  text-overflow: ellipsis;
}
input::-moz-placeholder {
  text-overflow: ellipsis;
}
input:-moz-placeholder {
  text-overflow: ellipsis;
}
input:-ms-input-placeholder {
  text-overflow: ellipsis;
}
:focus::-webkit-input-placeholder {
  transition: opacity 0.3s ease;
  opacity: 0;
}
:focus::-moz-placeholder {
  transition: opacity 0.3s ease;
  opacity: 0;
}
:focus:-moz-placeholder {
  transition: opacity 0.3s ease;
  opacity: 0;
}
:focus:-ms-input-placeholder {
  transition: opacity 0.3s ease;
  opacity: 0;
}
::-webkit-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
::-moz-placeholder {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
:-moz-placeholder {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
:-ms-input-placeholder {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}
:focus::-webkit-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
:focus::-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
:focus:-moz-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
:focus:-ms-input-placeholder {
  opacity: 0;
  transition: opacity 0.3s ease;
}
input {
  background-color: inherit;
  background-image: inherit;
  color: inherit;
}
input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  border: 0;
  color: #fff;
  -webkit-text-fill-color: #fff;
  box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0,
    rgba(0, 174, 255, 0.04) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0.03) 100%
  );
}
* {
  box-sizing: border-box !important;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
}
input[type='number'] {
  -moz-appearance: textfield;
}
body,
html {
  min-height: 100vh;
  height: auto;
}
.default.text,
input::placeholder,
textarea::placeholder {
  opacity: 1;
}
/*a:hover {*/
/*  color: inherit !important;*/
/*  text-decoration: inherit !important;*/
/*}*/

input:-webkit-autofill {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

html {
  overflow-y: scroll;
}
html,
body {
  height: 100%;
}

img {
  max-width: 100%;
}

.MuiPaper-elevation8 {
  box-shadow:
    0px 5px 5px -3px rgba(0, 0, 0, 0.1),
    0px 8px 10px 1px rgba(0, 0, 0, 0.06),
    0px 3px 14px 2px rgba(0, 0, 0, 0.06) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:focus input:-webkit-autofill,
input:-webkit-autofill:hover,
select:-webkit-autofill,
select:-webkit-autofill:focus,
select:-webkit-autofill:hover,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus {
  -webkit-text-fill-color: inherit;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#CHAT_WRAPPER_ID::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#CHAT_WRAPPER_ID {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#CHAT_WRAPPER_ID::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
#CHAT_WRAPPER_ID {
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
}

::-webkit-scrollbar {
  width: 0px; /* remove scrollbar space /
  background: transparent; / optional: just make scrollbar invisible /
  }
  / optional: show position indicator in red */
}
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0);
}

.__jivoMobileButton {
  margin-bottom: 60px !important;
}

.button_ba21 {
  width: 50px !important;
  height: 50px !important;
}

.logoIconCloud_afa8 {
  background-size: 20px !important;
  background-position: center center !important;
}

.markdown em {
  font-weight: bold;
}

.markdown strong {
  font-weight: bold;
}

.markdown a {
  color: inherit;
  transition: opacity 0.5s;
}

.markdown img {
  display: none;
  visibility: hidden;
}

.markdown a:hover {
  opacity: 0.7;
}

.markdown {
  display: grid;
  grid-gap: 16px;
}

.markdown ul {
  padding-left: 16px;
}

.markdown ol {
  padding-left: 16px;
}

.markdown ul li {
  list-style-type: disc;
}

.markdown ul li:not(:last-of-type) {
  margin-bottom: 8px;
}

.markdown ol li {
  list-style-type: decimal;
}

.markdown ol li:not(:last-of-type) {
  margin-bottom: 8px;
}

.markdown h1 {
  font-size: 16px;
  font-weight: bold;
}

body {
  background-color: #232540;
}
